import React from "react";
import { graphql, Link } from "gatsby";
import { format } from "date-fns";

import SEO from "../../components/seo";
import {
  mapEdgesToNodes,
  filterOutDocsPublishedInTheFuture,
  cn
} from "../../lib/helpers";

import { boxUl, boxLinkInvert } from "../../components/box-list.module.css";
import * as styles from "../../components/cycle-page.module.css";

export const query = graphql`
  query CounterfactualPageQuery {
    posts: allSanityCounterfactualPost(
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          title
          publishedAt
          slug {
            current
          }
        }
      }
    }
  }
`;

const CounterfactualPage = ({ data }) => {
  const postNodes = data?.posts && mapEdgesToNodes(data.posts);
  const publishedNodes = postNodes?.filter(filterOutDocsPublishedInTheFuture);
  const hasPosts = !!publishedNodes?.length;

  return (
    <>
      <SEO title="Counterfactual" />
      <ul className={boxUl}>
        <li>
          <h1>
            <Link
              to="./context"
              className={cn(styles.titleLink, boxLinkInvert)}
            >
              Counterfactual
            </Link>
            {!hasPosts && " (coming soon)"}
          </h1>
        </li>
        {hasPosts &&
          publishedNodes.map((post) => (
            <li key={post.id}>
              <Link to={post.slug.current}>
                {format(new Date(post.publishedAt), "yyMMdd")}&nbsp;–{" "}
                {post.title}
              </Link>
            </li>
          ))}
      </ul>
    </>
  );
};

export default CounterfactualPage;
